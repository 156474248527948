@import '../../../../scss/theme-bootstrap';

.basic-carousel {
  text-align: center;
  margin-#{$ldirection}: auto;
  margin-#{$rdirection}: auto;
  position: relative;
  &__header {
    text-align: center;
    margin-bottom: 0px;
    &-text {
      margin-top: 30px;
      p {
        margin-bottom: 5px;
      }
    }
  }
  &-formatter {
    .slick-next {
      right: 10px;
      @include breakpoint($medium-up) {
        right: 70px;
      }
    }
    .slick-prev {
      left: 3px;
      @include breakpoint($medium-up) {
        left: 60px;
      }
    }
  }
}
